import React, { useEffect, memo } from "react";

import SEO from "../components/layout/seo";

import BlocoBanners from '../components/home/bloco-banners';
import BlocoServicos from '../components/home/bloco-servicos';
import BlocoNossaEmpresa from '../components/home/bloco-nossa-empresa';
import BlocoRessarcitometro from '../components/home/bloco-ressarcitometro';
//import BlocoNossosClientes from '../components/home/bloco-nossos-clientes';

import Aos from 'aos';




const onFocus = () =>
{
    //console.log('PaginaInicial:','onFocus');
    Aos.refresh();
}

const TempPaginaInicial = () =>
{
    useEffect(() =>
    {
        //
        // componentDidMount

        //console.log('PaginaInicial:','render');

        if ( typeof Aos !== `undefined` && typeof window !== `undefined` ) {
            setTimeout(function() {
                //Aos.refresh();
                Aos.init();
            }, 500);
            window.addEventListener("focus", onFocus);
        }

        //
        // componentWilUnmount

        return () => {
            //console.log('PaginaInicial:','unmount');

            if ( typeof Aos !== `undefined` && typeof window !== `undefined` )
            {
                window.removeEventListener("focus", onFocus);
                //Aos.disable();
            }
        };
    }, []);

    return (
        <>
            <SEO />
            {(typeof window !== `undefined`) ? <BlocoBanners /> : <div className="banner" key={`sitebanner`}></div>}
            <BlocoNossaEmpresa />
            <BlocoRessarcitometro />
            <BlocoServicos />
            {/* {(typeof window !== `undefined`) ? <BlocoNossosClientes /> : <div className="nossosclientes" key={`siteclientes`}></div>} */}
            {/* <div className="button__up ion chevron-up" title="Clique aqui para subir" /> */}
        </>
    )
}

const PaginaInicial = memo(TempPaginaInicial);

export default PaginaInicial;