import React, { memo } from 'react';

import { useStaticQuery, graphql } from "gatsby";

import Space from '../space';


const ServicosPicture = ({name}) =>
{
    const data = useStaticQuery(graphql`
        query {
            service1: file(relativePath: { eq: "service1.png" }) {
                childImageSharp {
                    fluid(maxWidth: 300) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            service2: file(relativePath: { eq: "service2.png" }) {
                childImageSharp {
                    fluid(maxWidth: 300) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            service3: file(relativePath: { eq: "service3.png" }) {
                childImageSharp {
                    fluid(maxWidth: 300) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            service4: file(relativePath: { eq: "service4.png" }) {
                childImageSharp {
                    fluid(maxWidth: 300) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    if ( name === 'service1.png' ) {
        return <img draggable="false" src={data.service1.childImageSharp.fluid.src} alt="" width="76" height="64" />
    } else if ( name === "service2.png" ) {
        return <img draggable="false" src={data.service2.childImageSharp.fluid.src} alt="" width="61" height="54" />
    } else if ( name === "service3.png" ) {
        return <img draggable="false" src={data.service3.childImageSharp.fluid.src} alt="" width="70" height="71" />
    } else if ( name === "service4.png" ) {
        return <img draggable="false" src={data.service4.childImageSharp.fluid.src} alt="" width="69" height="68" />
    }
}




const TempBlocoServicos = () =>
{
    /* useEffect(() => {
        console.log('BlocoServicos:','render');
        return () => {
            console.log('BlocoServicos:', 'unmount');
        };
    }, []); */

    return (
        <div className="nossosservicos" key={`siteservicos`}>
            <div className="container">
                <Space altura={20} />
                <h2 className="h2 h2--white h2--bold" data-aos="fade-in" data-aos-duration="1000">Por que utilizar nossos serviços?</h2>
                <Space altura={30} />
                <div className="services">
                    <div className="service__item" data-aos="fade-in" data-aos-duration="1000">
                        <div className="service__icon"><ServicosPicture name="service1.png" /></div>
                        <div className="service__title">Aumente sua competitividade</div>
                        <div className="service__text">Recupere créditos tributários administrativamente, com segurança e agilidade.</div>
                    </div>
                    <div className="service__item" data-aos="fade-in" data-aos-duration="1000">
                        <div className="service__icon"><ServicosPicture name="service2.png" /></div>
                        <div className="service__title">Relacionamento sólido</div>
                        <div className="service__text">Este é o caminho que seguimos para criar valor e garantir a melhor experiência do nosso cliente.</div>
                    </div>
                    <div className="service__item" data-aos="fade-in" data-aos-duration="1000">
                        <div className="service__icon"><ServicosPicture name="service3.png" /></div>
                        <div className="service__title">Soluções tributárias</div>
                        <div className="service__text">Com serviço de inteligência fiscal e tributária, levamos ao cliente soluções tributárias.</div>
                    </div>
                    <div className="service__item" data-aos="fade-in" data-aos-duration="1000">
                        <div className="service__icon"><ServicosPicture name="service4.png" /></div>
                        <div className="service__title">Segurança e rapidez</div>
                        <div className="service__text">Com a recuperação tributária administrativa, o trabalho ocorre de forma rápida e segura, levantando valores para o caixa da empresa.</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const BlocoServicos = memo(TempBlocoServicos);

export default BlocoServicos;