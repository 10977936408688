import React, { Component } from 'react';

import { Link } from 'gatsby';

import Space from '../space';

import TinySlider from "tiny-slider-react";


const BotaoBanner = ({children, banner_redirect, banner_link, banner_temconteudo, banner_alvo}) =>
{
    const link_interno = ( banner_link.indexOf('arinteligenciatributaria.com.br') !== -1 );

    let banner_link_pathname = link_interno === true ? banner_link.replace('https://', '').replace('http://', '').replace('www.', '').replace('arinteligenciatributaria.com.br', '') : banner_link;

    return banner_temconteudo === 0 || banner_redirect === false ? children : (
        link_interno === true ? 
        (
            banner_alvo === "n"
            ?
                <Link className="banner__button" to={`${banner_link_pathname}`}>{children}</Link>
            :
                <a className="banner__button" href={banner_link} target="_blank" rel="noreferrer">{children}</a>
        )
        : 
        (
            banner_alvo === "n"
            ?
                <a className="banner__button" href={banner_link} rel="noreferrer nofollow">{children}</a>
            :
                <a className="banner__button" href={banner_link} target="_blank" rel="noreferrer nofollow">{children}</a>
        )
    );

    // quando o banner não tem conteúdo personalizado, também não usa BotaoBanner...
}

const BotaoBannerPicture = ({children, banner_redirect, banner_link, banner_temconteudo, banner_alvo}) =>
{
    const link_interno = ( banner_link.indexOf('arinteligenciatributaria.com.br') !== -1 );

    let banner_link_pathname = link_interno === true ? banner_link.replace('https://', '').replace('http://', '').replace('www.', '').replace('arinteligenciatributaria.com.br', '') : banner_link;

    return banner_temconteudo === 1 || banner_redirect === false ? children : (
        link_interno === true ? 
        (
            banner_alvo === "n"
            ?
                <Link to={`${banner_link_pathname}`}>{children}</Link>
            :
                <a href={banner_link} target="_blank" rel="noreferrer">{children}</a>
        )
        : 
        (
            banner_alvo === "n"
            ?
                <a href={banner_link} rel="noreferrer nofollow">{children}</a>
            :
                <a href={banner_link} target="_blank" rel="noreferrer nofollow">{children}</a>
        )
    );

    // quando o banner tem conteúdo personalizado, a imagem (children) não deve ser clicável
}







class BlocoBanners extends Component
{
    constructor(props)
    {
        super(props);

        let banners = [];
        let bannersLoaded = [];

        if (typeof window !== `undefined`)
        {
            let tmp__banners = window.sessionStorage.getItem('banners');

            banners = tmp__banners === null ? [] : JSON.parse(tmp__banners);
        }

        this.state = {
            banners,
            bannersLoaded,
            bannersCached: banners.length === 0 ? false : true,
            settings:
            {    
                //lazyload: true,
                //nav: false,
                //mouseDrag: true
                //container: '#banners',
                //controlsContainer: '.banners__controls',

                animateIn: 'tns-fadeIn__banner',
                animateOut: 'tns-fadeOut__banner',
                animateNormal: 'tns-normal__banner',
                mode: 'gallery',
                controls: false, // remove built-in nav buttons
                //autoplay: true,
                //autoplayTimeout: 6000,
                loop: false, // fix cloned banners with gatsby-image
                rewind: true,
                items: 1,
                slideBy: 1,
                touch: true,
                swipeAngle: 45,
                preventActionWhenRunning: true,
                speed: 700,
                animateDelay: 2000
            }
        }

        this.getBanners = this.getBanners.bind(this);

        //console.log('BlocoBanners[constructor]');
        //console.log('BlocoBanners[constructor]:',banners);
    }

    onGoTo = dir => this.ts.slider.goTo(dir)
    goToPrev = () => this.onGoTo('prev')
    goToNext = () => this.onGoTo('next')

    async getBanners()
    {
        //await fetch("https://aeradmin.agencianeoplan.com.br/wp-json/wp/v2/banner?orderby=menu_order&order=asc")
        await fetch("https://cms.arinteligenciatributaria.com.br/wp-json/wp/v2/banner?orderby=menu_order&order=asc")
        .then((response) => {
            if (response.status >= 400 && response.status < 600) {
              throw new Error("banners: Bad response from server");
            }
            return response.text();
        })
        .then((text) => {
            ////console.log('text:',text);

            const obj = JSON.parse(text);

            ////console.log('banners: ', obj);

            let banners = [];

            obj.forEach((banner) =>
            {
                const {
                    banner_desktop,
                    banner_desktop_alt,
                    banner_mobile,
                    banner_mobile_alt,
                    banner_redirect,
                    banner_link,
                    banner_alvo,
                    banner_temconteudo,
                    banner_titulo,
                    banner_subtitulo,
                    banner_botao,

                    banner_fonte_do_titulo,
                    banner_fonte_do_subtitulo,
                    banner_cor_do_titulo,
                    banner_cor_do_subtitulo,
                    banner_grossura_do_titulo_rubik,
                    banner_grossura_do_subtitulo_rubik,
                    banner_grossura_do_titulo_lato,
                    banner_grossura_do_subtitulo_lato,
                    banner_grossura_do_titulo_os,
                    banner_grossura_do_subtitulo_os,

                    banner_alinhamento_horizontal,
                    banner_alinhamento_vertical,
                } = banner;

                banners.push({
                    banner_title: banner.title.rendered,
                    banner_desktop,
                    banner_desktop_alt,
                    banner_mobile,
                    banner_mobile_alt,
                    banner_redirect,
                    banner_link,
                    banner_alvo,
                    banner_temconteudo,
                    banner_titulo,
                    banner_subtitulo,
                    banner_botao,

                    banner_fonte_do_titulo,
                    banner_fonte_do_subtitulo,
                    banner_cor_do_titulo,
                    banner_cor_do_subtitulo,
                    banner_grossura_do_titulo_rubik,
                    banner_grossura_do_subtitulo_rubik,
                    banner_grossura_do_titulo_lato,
                    banner_grossura_do_subtitulo_lato,
                    banner_grossura_do_titulo_os,
                    banner_grossura_do_subtitulo_os,

                    banner_alinhamento_horizontal,
                    banner_alinhamento_vertical,
                });
            });

            this.setState({
                banners,
            }, () => {
                if (typeof window !== `undefined`) {
                    window.sessionStorage.setItem('banners', JSON.stringify(banners));
                }
                //console.log('banners: ',banners);
            });
        })
        .catch((error) => {
            //console.log('banners error:',error);
        });
    }

    /* shouldComponentUpdate(nextProps, nextState)
    {
        return (
            
        );
    } */

    render()
    {
        //console.log('BlocoBanners:','render');
        //console.log('BlocoBanners[banners/bannersLoaded]:',this.state.banners.length*2,' / ',this.state.bannersLoaded.length);

        return (
            <div className="banner" key={`sitebanner`}>
                {
                    this.state.banners.length === 0 && this.state.bannersCached === false
                    ?
                    (
                        <div className="banner__item">
                            {/* <div className="desktop" style={{width: '100%', maxWidth: 1920, height: 779, backgroundColor: '#f2f2f2'}}></div> */}
                            <div className="desktop" style={{width: '100%', maxWidth: 1920, height: 773}}>
                                <div className="smooth-image-wrapper"><div className="smooth-preloader"><span className="loader" /></div></div>
                            </div>
                            <div className="mobile" style={{width: '100%', maxWidth: 600, height: 600}}>
                                <div className="smooth-image-wrapper"><div className="smooth-preloader"><span className="loader" /></div></div>
                            </div>
                        </div>
                    )
                    :
                    (
                        <TinySlider settings={this.state.settings} ref={ts => this.ts = ts}>
                            {this.state.banners.map((banner, banner_index) =>
                            {
                                const {
                                    banner_title,
                                    banner_desktop,
                                    banner_desktop_alt,
                                    banner_mobile,
                                    banner_mobile_alt,
                                    banner_redirect,
                                    banner_link,
                                    banner_alvo,
                                    banner_temconteudo,
                                    banner_titulo,
                                    banner_subtitulo,
                                    banner_botao,

                                    banner_fonte_do_titulo,
                                    banner_fonte_do_subtitulo,
                                    banner_cor_do_titulo,
                                    banner_cor_do_subtitulo,
                                    banner_grossura_do_titulo_rubik,
                                    banner_grossura_do_subtitulo_rubik,
                                    banner_grossura_do_titulo_lato,
                                    banner_grossura_do_subtitulo_lato,
                                    banner_grossura_do_titulo_os,
                                    banner_grossura_do_subtitulo_os,

                                    banner_alinhamento_horizontal,
                                    banner_alinhamento_vertical,
                                } = banner;


                                /**
                                 * 
                                 * CONTENT ALIGNMENT
                                 * 
                                 */

                                let posicionamento = 'banner__content';

                                // CONTENT HORIZONTAL ALIGMENT
                                if ( banner_alinhamento_horizontal === 'c' ) {
                                    posicionamento += ' banner--center';
                                } else if ( banner_alinhamento_horizontal === 'd' ) {
                                    posicionamento += ' banner--right';
                                } else if ( banner_alinhamento_horizontal === 'e' ) {
                                    posicionamento += ' banner--left';
                                }

                                // CONTENT VERTICAL ALIGMENT
                                if ( banner_alinhamento_vertical === 't' ) {
                                    posicionamento += ' banner--top';
                                } else if ( banner_alinhamento_vertical === 'c' ) {
                                    posicionamento += ' banner--middle';
                                } else if ( banner_alinhamento_vertical === 'r' ) {
                                    posicionamento += ' banner--bottom';
                                }


                                /**
                                * 
                                * FONTE
                                * 
                                */

                                let fonte_do_titulo = ' font--rubik';
                                let fonte_do_subtitulo = ' font--rubik';

                                // TÍTULO
                                if ( banner_fonte_do_titulo === 'lato' ) {
                                    fonte_do_titulo = ' font--lato';
                                } else if ( banner_fonte_do_titulo === 'os' ) {
                                    fonte_do_titulo = ' font--os';
                                }

                                // SUBTÍTULO
                                if ( banner_fonte_do_subtitulo === 'lato' ) {
                                    fonte_do_subtitulo = ' font--lato';
                                } else if ( banner_fonte_do_subtitulo === 'os' ) {
                                    fonte_do_subtitulo = ' font--os';
                                }


                                /**
                                * 
                                * GROSSURA
                                * 
                                */

                                // GROSSURA DEFINIDA POR FONTE (NO BACK-END)
                                let grossura_titulo = '';
                                let grossura_subtitulo = '';

                                if (banner_fonte_do_titulo === 'rubik')
                                    grossura_titulo = banner_grossura_do_titulo_rubik;
                                if (banner_fonte_do_titulo === 'lato' )
                                    grossura_titulo = banner_grossura_do_titulo_lato;
                                if (banner_fonte_do_titulo === 'os'   )
                                    grossura_titulo = banner_grossura_do_titulo_os;

                                if (banner_fonte_do_subtitulo === 'rubik')
                                    grossura_subtitulo = banner_grossura_do_subtitulo_rubik;
                                if (banner_fonte_do_subtitulo === 'lato' )
                                    grossura_subtitulo = banner_grossura_do_subtitulo_lato;
                                if (banner_fonte_do_subtitulo === 'os'   )
                                    grossura_subtitulo = banner_grossura_do_subtitulo_os;


                                // TÍTULO

                                // aplica grossura mínima por fonte
                                let grossura_do_titulo = '';
                                if ( banner_fonte_do_titulo === 'rubik' || banner_fonte_do_titulo === 'os' )
                                    grossura_do_titulo = ' font--light';
                                if ( banner_fonte_do_titulo === 'lato' )
                                    grossura_do_titulo = ' font--thin';

                                // aplica grossura escolhida (se ela existir na fonte)
                                if ( grossura_titulo === '100' ) {
                                    grossura_do_titulo = ' font--thin'; // lato
                                } else if ( grossura_titulo === '300' ) {
                                    grossura_do_titulo = ' font--light'; // todas
                                } else if ( grossura_titulo === '400' ) {
                                    grossura_do_titulo = ' font--normal'; // todas
                                } else if ( grossura_titulo === '500' ) {
                                    grossura_do_titulo = ' font--medium'; // rubik
                                } else if ( grossura_titulo === '600' ) {
                                    grossura_do_titulo = ' font--semibold'; // open sans
                                } else if ( grossura_titulo === '700' ) {
                                    grossura_do_titulo = ' font--bold'; // todas
                                } else if ( grossura_titulo === '800' ) {
                                    grossura_do_titulo = ' font--extrabold'; // open sans
                                } else if ( grossura_titulo === '900' ) {
                                    grossura_do_titulo = ' font--black'; // lato , rubik
                                }

                                // SUBTÍTULO

                                // aplica grossura mínima por fonte
                                let grossura_do_subtitulo = '';
                                if ( banner_fonte_do_subtitulo === 'rubik' || banner_fonte_do_subtitulo === 'os' )
                                    grossura_do_subtitulo = ' font--light';
                                if ( banner_fonte_do_subtitulo === 'lato' )
                                    grossura_do_subtitulo = ' font--thin';

                                // aplica grossura escolhida (se ela existir na fonte)
                                if ( grossura_subtitulo === '100' ) {
                                    grossura_do_subtitulo = ' font--thin'; // lato
                                } else if ( grossura_subtitulo === '300' ) {
                                    grossura_do_subtitulo = ' font--light'; // todas
                                } else if ( grossura_subtitulo === '400' ) {
                                    grossura_do_subtitulo = ' font--normal'; // todas
                                } else if ( grossura_subtitulo === '500' ) {
                                    grossura_do_subtitulo = ' font--medium'; // rubik
                                } else if ( grossura_subtitulo === '600' ) {
                                    grossura_do_subtitulo = ' font--semibold'; // open sans
                                } else if ( grossura_subtitulo === '700' ) {
                                    grossura_do_subtitulo = ' font--bold'; // todas
                                } else if ( grossura_subtitulo === '800' ) {
                                    grossura_do_subtitulo = ' font--extrabold'; // open sans
                                } else if ( grossura_subtitulo === '900' ) {
                                    grossura_do_subtitulo = ' font--black'; // lato , rubik
                                }


                                /**
                                * 
                                * JSX - Layout
                                * 
                                */

                                const allBannersLoaded = this.state.bannersLoaded.length === (this.state.banners.length * 2);// || this.state.bannersCached === true;

                                return (

                                    <div className="banner__item" key={banner_index}>

                                        <BotaoBannerPicture
                                          banner_redirect={banner_redirect}
                                          banner_temconteudo={banner_temconteudo}
                                          banner_link={banner_link}
                                          banner_alvo={banner_alvo}>

                                            <div className={`desktop smooth-image-wrapper${allBannersLoaded === true/* this.state.bannersLoaded.indexOf(banner_desktop) !== -1 */ ? ' wrapper-visible' : ''}`}>
                                                <img
                                                    role="presentation"
                                                    draggable="false"
                                                    src={banner_desktop}
                                                    alt={banner_desktop_alt}
                                                    title={banner_title}
                                                    className={`smooth-image image-${allBannersLoaded === true/* this.state.bannersLoaded.indexOf(banner_desktop) !== -1 */ ? 'visible' :  'hidden'}`}
                                                    onLoad={() => {
                                                        this.setState({
                                                            bannersLoaded: [...this.state.bannersLoaded, banner_desktop],
                                                        }/* , () => {
                                                            if (typeof window !== `undefined`) {
                                                                window.sessionStorage.setItem('bannersloaded', JSON.stringify(this.state.bannersLoaded));
                                                            }
                                                            //console.log('banner loaded:',banner_desktop);
                                                        } */);
                                                    }}
                                                />
                                                {allBannersLoaded === false/* this.state.bannersLoaded.indexOf(banner_desktop) === -1 */ && (
                                                <div className="smooth-preloader">
                                                    <span className="loader" />
                                                </div>
                                                )}
                                            </div>

                                        </BotaoBannerPicture>

                                        <BotaoBannerPicture
                                          banner_redirect={banner_redirect}
                                          banner_temconteudo={banner_temconteudo}
                                          banner_link={banner_link}
                                          banner_alvo={banner_alvo}>

                                            <div className={`mobile smooth-image-wrapper${allBannersLoaded === true/* this.state.bannersLoaded.indexOf(banner_mobile) !== -1 */ ? ' wrapper-visible' : ''}`}>
                                                <img
                                                    role="presentation"
                                                    draggable="false"
                                                    src={banner_mobile}
                                                    alt={banner_mobile_alt}
                                                    title={banner_title}
                                                    className={`smooth-image image-${allBannersLoaded === true/* this.state.bannersLoaded.indexOf(banner_mobile) !== -1 */ ? 'visible' :  'hidden'}`}
                                                    onLoad={() => {
                                                        this.setState({
                                                            bannersLoaded: [...this.state.bannersLoaded, banner_mobile],
                                                        }/* , () => {
                                                            if (typeof window !== `undefined`) {
                                                                window.sessionStorage.setItem('bannersloaded', JSON.stringify(this.state.bannersLoaded));
                                                            }
                                                            //console.log('banner loaded:',banner_mobile);
                                                        } */);
                                                    }}
                                                />
                                                {allBannersLoaded === false/* this.state.bannersLoaded.indexOf(banner_mobile) === -1 */ && (
                                                <div className="smooth-preloader">
                                                    <span className="loader" />
                                                </div>
                                                )}
                                            </div>

                                        </BotaoBannerPicture>

                                        {banner_temconteudo === 1 && <div className={posicionamento}>
                                            <div className={`banner__title${grossura_do_titulo}${fonte_do_titulo}`} style={{color: banner_cor_do_titulo}} dangerouslySetInnerHTML={{ __html: banner_titulo }} />
                                            <Space altura={10} />
                                            <div className={`banner__subtitle${grossura_do_subtitulo}${fonte_do_subtitulo}`} style={{color: banner_cor_do_subtitulo}} dangerouslySetInnerHTML={{ __html: banner_subtitulo }} />
                                            {
                                                banner_redirect === true && (
                                                    <BotaoBanner banner_redirect={banner_redirect} banner_temconteudo={banner_temconteudo} banner_link={banner_link} banner_alvo={banner_alvo}>{banner_botao}</BotaoBanner>
                                                )
                                            }
                                        </div>}

                                    </div>

                                );
                            })}
                        </TinySlider>
                    )
                }

                {this.state.banners.length !== 0 && (
                    <div className="banners__controls">
                        <button className="controls__button__1 ion chevron-left" onClick={this.goToPrev}>Prev</button>
                        <button className="controls__button__2 ion chevron-right" onClick={this.goToNext}>Next</button>
                    </div>
                )}
            </div>
        );
    }

    componentDidMount()
    {
        //console.log('BlocoBanners:','mount');

        if ( this.state.banners.length === 0 )
        {
            this.getBanners();
        }
    }

    componentDidUpdate()
    {
        //console.log('BlocoBanners:','update');
    }

    componentWillUnmount()
    {
        //console.log('BlocoBanners:','unmount');
    }
}

export default BlocoBanners;